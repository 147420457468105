<template>
  <div class="div-box">
    <!-- <van-cell title="出款类型" title-style="text-align: left;">
      {{ signForm.addressType }}
    </van-cell> -->
    <div class="div-top">
      <div class="div-top-top"><img src="@/assets/svg/icon_usdt.svg" />
        <div class="margin-l3"> {{ signForm.collect ? '归集' : '转账' }}</div>
      </div>
      <div class="div-top-center">
        <img src="@/assets/svg/move_up.svg" />
        <span style="margin-right: 0.5rem; font-size: 1.5rem; font-weight: bold;">{{ signForm.amount }}</span>
        <span style="font-size: 12px;">{{ signForm.assetName === 'TRC20' ? 'USDT' : signForm.assetName }}</span>
      </div>
      <div class="transfer-box">
        <div class="address-box">
          <p class="flex-center"><img src="@/assets/svg/money_out.svg" /><span class="margin-l2">
              {{ signForm.collect ? '归集地址' : '付款地址' }}
            </span></p>
          <p>{{ signForm.payAddress }}</p>
        </div>
        <img src="@/assets/svg/transfer.svg" />
        <div class="address-box">
          <p class="flex-center"><img src="@/assets/svg/money_in.svg" /><span class="margin-l2"> {{ signForm.collect ?
            '归入地址' :'收款地址' }} </span></p>
          <p>{{ signForm.address }}</p>
        </div>
      </div>
    </div>
    <van-cell title="订单编号" title-style="text-align: left;">
      {{ signForm.orderNo }}
    </van-cell>
    <van-cell title="创建时间" title-style="text-align: left;">
      {{ signForm.createTime }}
    </van-cell>
    <van-cell title="过期时间" title-style="text-align: left;">
      {{ signForm.expiredTime }}
    </van-cell>
    <div class="div-footer">
      <!-- 拒绝出款 -->
      <van-button @click="handleRefuse"
        style="height: 35px; border-radius: 10px; width: 48%; background-color: #DA125A; color: #ffffff;">
        {{ signForm.collect ? '停止归集' : '拒绝出款' }}
      </van-button>
      <van-button @click="handleConfirm"
        style="height: 35px; border-radius: 10px; width: 48%; background-color: #328BF4; color: #ffffff;">
        {{ signForm.collect ? '开始签名' : '同意出款' }}
      </van-button>
    </div>

    <van-dialog v-model:show="show" title="填写原因" show-cancel-button @confirm="handleDialogConfirm">
      <van-cell-group inset>
        <van-field v-model="refuseReason" label="原因：" placeholder="请输入原因" />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { refusePay, collectClose } from "@/api/index";
export default {
  name: 'payInfo',
  props: {
    signForm: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      show: false,
      refuseReason: "",
    };
  },
  computed: {
    formatDateTimeForHMSS() {
      return (obj) => {
        debugger;
        if (obj == null) {
          return null
        }
        let date = new Date(parseFloat(obj));
        let y = 1900 + date.getYear();
        let m = "0" + (date.getMonth() + 1);
        let d = "0" + date.getDate();
        let h = "0" + date.getHours();
        let mm = "0" + date.getMinutes();
        let s = date.getSeconds();
        if (s.toString().length < 2) {
          s = "0" + s;
        }
        return y + "-" + m.substring(m.length - 2, m.length) + "-" + d.substring(d.length - 2, d.length) + " " + h.substring(h.length - 2, h.length) + ":" + mm.substring(mm.length - 2, mm.length) + ":" + s;
      }
    }
  },
  methods: {

    async handleConfirm() {
      this.$emit("handle-confirm", this.signForm);
    },
    handleRefuse() {
      if (this.signForm.collect) {
        const obj = { orderId: this.signForm.orderNo };
        collectClose(obj)
          .then(response => {
            if (response.code === 200) {
              Notify({ type: 'success', message: '已取消支付' });
              this.$emit("handle-cancel-confirm", this.signForm);
            } else {
              Notify({ type: 'warning', message: response.msg });
            }
          }).catch(err => {
            Notify({ type: '请求失败:', message: err });
          });
      } else {
        this.show = true;
      }
    },
    handleDialogConfirm() {
      refusePay({
        orderId: this.signForm.orderNo,
        remarks: this.refuseReason
      }).then(response => {
        if (response.code === 200) {
          Notify({ type: 'success', message: '已取消支付' });
          this.$emit("handle-cancel-confirm", this.signForm);
        } else {
          Notify({ type: 'warning', message: response.msg });
        }
      }).catch(err => {
        Notify({ type: '请求失败:', message: err });
      });
    },
  },
};
</script>
<style>
.div-container {
  padding: 0.8rem;
}

.div-box {
  margin-bottom: 0.8rem;
  border-radius: 12px;
  background-color: #ffffff;
  color: #ffffff;
}

.div-top {
  background: url('@/assets/svg/box_bg.svg') no-repeat top center;
  background-size: 120%;
  border-radius: 12px;
  /* background-color: #147a67; */
  /* box-shadow: 0px 0px 6px #ffffff inset; */

}

.div-top-top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
}

.div-top-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0rem;
}

.transfer-box {
  padding: 0.6rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transfer-box .address-box {
  background-color: rgba(255, 255, 255, 0.4);
  width: 46%;
  word-wrap: break-word;
  /* 允许单词内断行 */
  white-space: normal;
  /* 处理空白符，自动换行 */
  border-radius: 8px;
  font-size: 0.7rem;
  text-align: left;
}

.transfer-box p {
  padding: 0px;
  margin: 0.5rem 0.4rem;
}

.div-footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
}

.margin-l2 {
  margin-left: 0.2rem;
}

.margin-l3 {
  margin-left: 0.3rem;
}
</style>

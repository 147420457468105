import request from '@/utils/request'

export function loginAction(data){
    return request({
        url: '/tronlink/login',
        method: 'post',
        params: data
    })
}

export function paySuccess(data) {
    return request({
        url: '/tronlink/pay/success',
        method: 'post',
        data: data
    })
}

export function collectSuccess(data) {
    return request({
        url: '/tronlink/collect/success',
        method: 'post',
        data: data
    })
}

export function payMultSig(data) {
    return request({
        url: '/tronlink/pay/sign',
        method: 'post',
        data: data
    })
}

export function refusePay(data){
    return request({
        url: '/tronlink/pay/refuse',
        method: 'post',
        data: data
    })
}


export function collectClose(data){
    return request({
        url: '/tronlink/collect/close',
        method: 'post',
        params: data
    })
}

export function order(){
    return request({
        url: '/tronlink/order',
        method: 'get'
    })
}

export function subscribe(){
    return request({
        url: '/tronlink/poll',
        method: 'get',
        timeout: 180000
    })
}


export function accountResource(data){
    return request({
        url:'/tronlink/account/resource',
        method:'get',
        params:data
    })
}
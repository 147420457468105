import axios from "axios";
import {  Notify} from 'vant';

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api的base_url
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 300000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    if (   sessionStorage.getItem("token")) {
      config.headers["Clientid"] = "5C555B88BAEC9EC4A6E1075415463672"; //让每个请求携带账号
      config.headers["X-Authorization-Code"] ="Basic " +sessionStorage.getItem("token"); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    /**
     * 适配导出Excel文件流的情况
     */
    if (response.status == 200 && res.code == null) {
      return response;
    }
    // 401:未登录;
    // if (res.code === 401) {
    //   MessageBox.confirm(
    //     "你已被登出，可以取消继续留在该页面，或者重新登录",
    //     "确定登出",
    //     {
    //       confirmButtonText: "重新登录",
    //       cancelButtonText: "取消",
    //       type: "warning"
    //     }
    //   ).then(() => {
    //     store.dispatch("FedLogOut").then(() => {
    //       location.reload(); // 为了重新实例化vue-router对象 避免bug
    //     });
    //   });
    // } else if (res.code !== 200) {
    //   Message({
    //     message: res.message,
    //     type: "error"
    //   });
    //   // return Promise.reject("error");
    // }
    return response.data;
  },
  error => {
    // 轮训地址过滤
    console.log("debugger:",error);
    Notify({
      message: '请求失败:'+error.message,
      duration: 3000,
    });
    return Promise.reject(error);
  }
);

export default service;

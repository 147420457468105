<template>
    <div class="div-container">
        <div>
            <div v-if="dataList == null || dataList == undefined || dataList.length == 0">
                暂无数据
            </div>
            <div v-else>
                <payInfo v-for="item in dataList" :key="item.orderNo" :signForm="item" @handle-confirm="handleConfirm"
                    @handle-cancel-confirm="handleCancel" />
            </div>
        </div>
        <van-overlay :show="showOverlay">
            <div class="load">
                <!-- 中间的图案动效加载 -->
                <div class="sk-chase">
                    <div class="sk-chase-dot" v-for="(item, key) in 6" :key="key"></div>
                </div>
                <!-- 文字加载 -->
                <span>{{ overContent }}</span>
            </div>
        </van-overlay>
        <div>{{ errorMsg }}</div>
    </div>
</template>

<script>
import { paySuccess, collectSuccess, payMultSig, loginAction, order, subscribe, accountResource } from '@/api/index';
import payInfo from "./components/pay-info.vue";


export default {
    components: {
        payInfo,
    },
    data() {
        return {
            errorMsg:'',
            overContent: '加载中...',
            showOverlay: true,
            isRefresh: false,
            // loading: false,
            address: '',
            dataList: []
        }
    },
    mounted() {
        this.initData();
        //    this.testData();
    },
    methods: {
        featchData() {
            subscribe().then(res => {
                if (res.code == 200) {
                    if (res.data.messageType == 'ORDER') {
                        this.dataList = this.dataList.concat(res.data.data);
                    } else {
                        this.initData();
                        return
                    }
                    this.featchData();
                }
                if (res.code == 504) {
                    this.featchData();
                }
                console.log("请求数据:", res);
            }).catch(err => {
                console.log("请求错误:", err);
                this.$dialog.confirm({
                    title: '错误提示',
                    message: '请求数据错误,请刷新重试',
                })
                    .then(() => {
                        featchData();
                        // on confirm
                    })
                    .catch(() => {
                        // on cancel
                    });
            });
        },
        async initData() {
            await this.initTronLink();
            loginAction({
                address: this.address
            }).then(res => {
                if (res.data.code == 200 && ![undefined, null, ''].includes(res.data.token)) {
                    sessionStorage.setItem("token", res.data.token);
                    this.initOrderData();
                }
                this.showOverlay = false;
            }).catch(err => {
                this.showOverlay = false;
                this.$dialog.alert({
                    message: '登录失败,服务不可用' + err,
                });
            });

        },
        async initOrderData() {
            order().then(res => {
                if (res.code == 200) {
                    this.dataList = res.rows;
                    this.featchData();
                }
            }).catch(err => {
                console.log(err);
            })
        },
        // 初始化
        async initTronLink() {
            let tronWeb;
            console.log(window.tronLink)
            if (window.tronLink.ready) {
                tronWeb = tronLink.tronWeb;
            } else {
                const res = await tronLink.request({ method: 'tron_requestAccounts' });
                if (res.code === 200) {
                    tronWeb = tronLink.tronWeb;
                } else {
                    this.$dialog.alert({
                        message: '授权失败,服务不可用',
                    });
                }
            }
            this.address = tronWeb.defaultAddress.base58;
            return tronWeb;

        },
        handleCancel(rowData) {
            const index = this.dataList.findIndex(item => item.orderNo === rowData.orderNo);
            if (index !== -1) {
                this.dataList.splice(index, 1);
            }
        },
        handleConfirm(rowData) {
            this.checkAccountResource(rowData)
        },
        paySuccessSubmit(res, rowData) {
            paySuccess({
                txId: res.txid,
                orderId: rowData.orderNo
            }).then(res => {
                if (res.code == 200) {
                    this.showOverlay = false;
                }
                this.handleCancel(rowData);
            }).catch(err => {
                this.$dialog.alert({
                    message: '提交失败：' + err + " 请手动处理",
                });
            });
        },
        collectSuccessSubmit(res, rowData) {
            collectSuccess({
                txId: res.txid,
                orderId: rowData.orderNo
            }).then(res => {
                if (res.code == 200) {
                    this.showOverlay = false;
                }
                this.handleCancel(rowData);
            }).catch(err => {
                this.$dialog.alert({
                    message: '提交失败：' + err + " 请手动处理",
                });
            });
        },
        payMultiSigSubmit(data) {
            payMultSig(data).then(res => {
                this.$dialog.alert({
                    message: '提交成功：' + res.msg,
                });
            }).catch(err => {
                this.$dialog.alert({
                    message: '提交失败：' + err,
                });
            })
        },
        async checkAccountResource(rowData) {
            accountResource({ address: rowData.payAddress, assetName: rowData.assetName })
                    .then(res => {
                        if (res.code == 200) {
                            const net = res.data.netLimit;
                            console.log("aaaa", net)
                            const balance = res.data.balance;
                            const energy = res.data.energyLimit;
                            if (net < 300 || (rowData.assetName == 'TRC20' && energy < 30000) || balance < rowData.amount) {
                                this.$dialog.confirm({
                                    title: '警告',
                                    message: '资源不足,带宽(300):' + net + ' 能量(30000):' + energy + ' 余额(' + rowData.amount + '):' + balance + '，是否消耗手续费继续进行签名',
                                })
                                    .then(() => {
                                        this.startSign(rowData);
                                        // on confirm
                                    }).catch(() => {
                                        // on cancel
                                    });
                            }else{
                                this.startSign(rowData);
                            }
                        }
                    }).catch(err => {
                        this.$dialog.confirm({
                            title: '错误提示',
                            message: '查询资源失败,是否继续进行签名',
                        })
                            .then(() => {
                                this.startSign(rowData);
                            }).catch(() => {
                                // on cancel
                            });
                    });
        },
        async startSign(rowData) {
            if (window.tronLink.ready) {
                this.showOverlay = true;
                this.overContent = "开始请求签名数据...";
                const tronweb = tronLink.tronWeb;
                const data = rowData.signBody;
                const str = TronWeb.toUtf8(data);
                const tra = JSON.parse(str);
                const widthTrxRes = await tronWeb.trx.getSignWeight(tra);
                
                tronWeb.trx.multiSign(widthTrxRes.transaction.transaction, undefined, rowData.permissionId).then(signedTx => {
                    this.overContent = "正在广播...";

                    if (rowData.last) {
                        tronweb.trx.sendRawTransaction(signedTx).then(res => {
                            if (res.result) {
                                this.overContent = "广播成功,正在提交数据...";
                                if (rowData.collect) {
                                    this.collectSuccessSubmit(res, rowData);
                                } else {
                                    this.paySuccessSubmit(res, rowData);
                                }
                            } else {
                                this.showOverlay=false;
                                this.errorMsg = JSON.stringify(res);
                                alert(res.result);
                            }
                        }).catch(err => {
                            this.$dialog.alert({
                                message: '广播失败:' + err + "  请确认交易之后处理",
                            });
                        })
                    } else {
                        this.payMultiSigSubmit({
                            signBody: TronWeb.toHex(JSON.stringify(signedTx)),
                            orderId: rowData.orderNo
                        });
                        this.handleCancel(rowData);
                    }

                }).catch(err => {
                    this.showOverlay = false;
                    this.$dialog.alert({
                        message: '签名失败：' + err,
                    });
                })
            } else {
                this.initTronLink();
            }
        }
    }


}
</script>
<style>
body {
    background-color: #cce0dc;
}

.div-container {
    height: calc(100%-50px)
}


.load {
    color: #dfdfdf;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    border-radius: 8px;
    background: none;
    z-index: 2;

    span {
        position: absolute;
        bottom: 15%;
        left: 25%;
    }
}

.sk-chase {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: 35%;
    animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #dfdfdf;
    border-radius: 100%;
    animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
    animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
    animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
    animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
    animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
    animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
    animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
    animation-delay: -0.6s;
}

@keyframes sk-chase {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot {

    80%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4);
    }

    100%,
    0% {
        transform: scale(1);
    }
}
</style>
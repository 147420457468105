import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button ,Toast, Cell, CellGroup, Field, Dialog, Notify,Loading,Overlay,PullRefresh} from 'vant';
import 'vant/lib/button/style';

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Notify);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(PullRefresh);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



